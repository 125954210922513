@import '../../styles/variables';


@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.login {
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px 32px;
  flex: 1;
  z-index: 11;
  justify-content: center;
  background: $black70;

  .loginContent {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 720px;
    flex-shrink: 0;
    border-radius: 20px;
    overflow: hidden;
  }

  .cross {
    position: absolute;
    transform: translate(336px, -184px);
    width: 48px;
    height: 48px;
    cursor: pointer;

    &:after,
    &:before {
      height: 24px;
      width: 2px;
      background-color: $white80;
      top: 12px;
      left: 23px;
      position: absolute;
      content: '';
    }

    &:after {
      transform: rotate(45deg);
    }

    &:before {
      transform: rotate(-45deg);
    }
  }

  .headerLogo {
    height: 24px;
    width: auto !important;
  }

  .charLoginImage {
    height: auto;
    width: 100%;
    margin: 0;
  }

  .loginImageContainer {
    display: flex;
    justify-content: center;
    width: 58.33%;
  }

  .signupSection {
    display: flex;

    &.mobileSignup {
      flex-direction: column-reverse;
    }
  }

  .signupInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 286px;
    flex: 1;
    border-radius: 20px 0px 0px 20px;
    border: 1px solid $translucentWhite;
    background: radial-gradient(28.18% 45% at 0% 58.82%, rgba(103, 94, 255, 0.10) 0%, rgba(103, 94, 255, 0.00) 100%), radial-gradient(30.99% 45.35% at 100% 31.43%, rgba(255, 89, 236, 0.10) 0%, rgba(255, 89, 236, 0.00) 100%), radial-gradient(28.11% 34.78% at 0% 0%, rgba(255, 189, 78, 0.10) 0%, rgba(255, 189, 78, 0.00) 100%), #171717;
    backdrop-filter: blur(60px);
    padding: 24px;
    align-items: flex-start;
    gap: 24px;

    .signupTextSection {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      align-self: stretch;
    }
  }

  .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 125%;
    color: $white;
    margin-bottom: 8px;
    align-self: stretch;
  }

  .subTitle {
    font-size: 14px;
    font-weight: 400;
    color: $white70;
    margin-bottom: 12px;
    align-self: stretch;
    background: linear-gradient(90deg, #72E9F1 0%, #7192E5 50%, #6257D7 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .loginOptions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    height: 160px;
    align-items: center;
    justify-content: center;

    .successMsg {
      font-size: 12px;
      font-weight: 600;
      line-height: 130%;
      color: $taglineColor;
      text-align: center;
      width: 100%;
    }

    .msgSentSection {
      display: flex;
      height: 113px;
      padding: 10px 16px;
      align-items: center;
      gap: 4px;
      align-self: stretch;
      border-radius: 16px;
      background: $borderWhite;

      p {
        flex: 1;
        color: $white50;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
      }
    }

    .enterButton {
      width: 20px;
      height: 20px;
      position: absolute;
      right: 36px;
      transform: translateY(50%);
      cursor: pointer;
    }


    .loader {
      width: 36px;
      height: 36px;
      border-radius: 100%;
      margin-right: 5px;
      border: 2px solid $white;
      border-right-color: $borderWhite;
      animation: rotate infinite 2s ease-in-out;
    }
  }

  .terms {
    color: $translucentWhite;
    font-size: 10px;
    font-weight: 400;
    line-height: 125%; /* 12.5px */
    letter-spacing: 0.2px;
    margin-top: 16px;

    a {
      font-size: 10px;
      font-weight: 400;
      color: $white80;
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-skip-ink: none;
      text-decoration-thickness: auto;
      text-underline-offset: auto;
      text-underline-position: from-font;
      margin: 0px 4px;

      &:first-child {
        margin-right: 12px;
      }

      &:active {
        color: $taglineColor;
      }
    }
  }

  .forgotPwd {
    font-size: 12px;
    font-weight: 600;
    line-height: 130%;
    color: $blueAscent;
    text-align: left;
    width: 100%;
    margin-bottom: 16px;
    cursor: pointer;
  }

  .signUpText {
    margin-top: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    cursor: pointer;

    span {
      &:first-child {
        font-size: 14px;
        font-weight: 400;
        color: $white70;
        margin-right: 12px;
      }

      &:last-child {
        font-size: 12px;
        font-weight: 600;
        line-height: 130%;
        color: $taglineColor;
      }
    }
  }

  .emailInput {
    width: 100%;
  }

  .userInput {
    border-radius: 24px;
    padding: 10px;
    width: 100%;
    outline: none;
    color: $white;
    background-color: $borderWhite;
    border: 1px solid transparent;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
    padding-right: 32px;
    font-size: 16px;
    font-weight: 400;

    &:hover {
      border-color: $borderWhite;
    }

    &:active,
    &:focus {
      border-color: $blueAscent;

      &:read-only {
        border-color: transparent;
      }
    }

    &:read-only {
      background-color: $lightWhiteBorder;
      color: $white40;
    }

    &::placeholder {
      color: $white40;
    }
  }

  .divider {
    display: flex;
    align-items: center;
    width: 100%;

    span {

      &:first-child,
      &:last-child {
        flex-grow: 1;
        height: 1px;
        background-color: $translucentWhite;
      }

      &:nth-child(2) {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 400;
        line-height: 130%;
        color: $translucentWhite;
        margin: 0 10px;
      }
    }
  }

  .loginBtn {
    border-radius: 24px;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $translucentWhite;
    width: 100%;
    cursor: pointer;

    &:hover {
      background-color: $white;

      p {
        color: $black;
      }
    }

    img {
      width: 24px !important;
      height: auto;
      margin-right: 14px;
    }

    p {
      font-size: 16px;
      font-weight: 600;
      line-height: 148%;
      color: $white;
    }
  }

  &.signUp {
    .headerLogo {
      margin-bottom: 26.5vh;
    }
  }

  &.desktopLogin {
    width: 76.5vw;
    padding: 64px;
    justify-content: center;

    .charLoginImage {
      margin-bottom: 16px;
    }

    &.signUp {
      padding-top: 40px;
    }
  }

  &.mobileLogin {
    height: 100%;
    width: 100%;
    padding: 0px;

    .loginContent {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      border-radius: 0px;

      .cross {
        transform: unset;
        top: 4px;
        right: 4px;
        z-index: 2;
      }

      .signupSection {
        width: 100%;
        height: 100%;

        .loginImageContainer {
          width: 100%;
          aspect-ratio: 1;
          border-radius: 0px;
          background: radial-gradient(80.56% 80.56% at 50% 19.44%, rgba(0, 0, 0, 0.00) 74.64%, rgba(41, 41, 41, 0.50) 84.76%, rgba(47, 47, 47, 0.80) 100%), url('../../../public/static/assets/images/loginModalImage.webp') lightgray 0px -40.733px / 105.072% 160.334% no-repeat;
          backdrop-filter: blur(60px);
          background-size: contain;
          background-position: top;
        }

        .signupInfo {
          border-radius: 20px 20px 0px 0px;
          margin-top: -30px;
          width: 100%;
          z-index: 2;
          padding: 89px 24px 24px 24px;

          .splashLogo {
            position: absolute;
            width: 102px;
            height: 98px;
            left: 50%;
            transform: translate(-50%, -128px);
          }
          
          .signupTextSection {
            text-align: center;

            .title {
              font-size: 32px;
              line-height: 100%;
            }
          }
        }
      }
    }
  }
}
