@import '../../styles/variables.scss';


@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.createPost {
  width: 100vw;
  background-color: $black70;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 10;

  .contentSection {
    position: absolute;
    left: 0;
    width: 100vw;
    bottom: 0;
    border-radius: 24px 24px 0px 0px;
    background-color: $borderWhite;
    backdrop-filter: blur(60px);
    padding: 16px 24px;

    .titleHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        &:first-child {
          font-size: 16px;
          font-weight: 600;
          line-height: 148%;
          color: $white;
        }

        &.crossIcon {
          width: 32px;
          height: 32px;
          padding: 8px;
          cursor: pointer;
          position: relative;

          &:hover {
            background: $translucentWhite;
            border-radius: 100%;
          }

          &:after,
          &:before {
            position: absolute;
            content: '';
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            width: 2px;
            height: 20px;
            background-color: $white80;
          }

          &:after {
            transform: translate(-50%, -50%) rotate(-45deg);
          }
        }
      }
    }

    .info {
      align-self: stretch;
      color: $white70;
      font-size: 14px;
      font-weight: 400;
      margin: 12px 0px 24px 0px;

      span {
        display: block; 
        margin-bottom: 8px; 
      }
    }

    .buttons {
      display: flex;
      justify-content: flex-end;
      gap: 8px;

      button {
        width: 100px;
      }
    }
  }

  .loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: $white;
    gap: 24px;

    .loader {
      width: 24px;
      height: 24px;
      border-radius: 100%;
      margin-right: 5px;
      border: 1px solid $white;
      border-right-color: $borderWhite;
      animation: rotate infinite 2s ease-in-out;
    }
  }

  .toast {
    padding: 11.2px 22.4px;
    border-radius: 22.4px;
    background-color: $userInputText;
    font-size: 14px;
    font-weight: 500;
    line-height: 130%;
    color: $white70;
    display: flex;
    gap: 8px;
    align-items: center;
    width: max-content;
    max-width: 80%;
    word-break: break-word;
    text-align: center;
    position: fixed;
    z-index: 11;
    left: 50%;
    transform: translateX(-50%);
    bottom: 100px;

    .successIcon {
      background-color: $borderGreen;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      aspect-ratio: 1;

      &:after {
        width: 4px;
        height: 9px;
        border-bottom: 2px solid $black;
        border-right: 2px solid $black;
        position: absolute;
        top: calc(50% - 7px);
        left: 32px;
        transform: rotate(38deg);
        content: '';
      }
    }
  }

  &.desktopCreatePost {
    .contentSection {
      width: 480px;
      left: 50%;
      top: 50%;
      bottom: unset;
      transform: translate(-50%, -50%);
      border-radius: 24px;
    }
  }
}